export default class AppHeader {
  constructor(container, options) {
    const defaultOptions = {
      mobileNavigation: false
    }

    this.options = Object.assign({}, defaultOptions, options)
    this.container = document.querySelector(container)
    this.mobileNavigation = this.options.mobileNavigation
    this.hamburgerMenu = this.container.querySelector('.icon-hamburger-menu')

    this.hamburgerMenu.addEventListener('click', this.onClickHamburgerMenu.bind(this))
    this.updateMobileNavigationClass()
    this.setEvents()
  }

  setEvents() {
    this.setActiveNavigationItem()
    this.onPageScroll()
  }

  onClickHamburgerMenu () {
    this.mobileNavigation = !this.mobileNavigation
    this.updateMobileNavigationClass()
  }

  updateMobileNavigationClass() {
    if (this.mobileNavigation) {
      this.hamburgerMenu.classList.add('mobile')
      this.container.classList.add('active')
    } else {
      this.hamburgerMenu.classList.remove('mobile')
      this.container.classList.remove('active')
    }
  }

  setActiveNavigationItem() {
    const path = window.location.pathname
    const links = this.container.querySelectorAll('a')
  
    links.forEach(link => {
      const href = link.getAttribute('href').replace(/\/$/, '')
      const currentPath = path.replace(/\/$/, '')
  
      if (currentPath.includes(href) && href !== '') {
        link.classList.add('active')
      } else if (href === '/' && currentPath === '') {
        link.classList.add('active')
      } else {
        link.classList.remove('active')
      }
    })
  }

  onPageScroll () {
    const header = this.container
    const headerHeight = 0
    const scrollClass = 'scroll-down'
  
    window.addEventListener('scroll', () => {
      if (window.scrollY <= headerHeight) {
        header.classList.remove(scrollClass)
      } else if (window.scrollY >= headerHeight) {
        header.classList.add(scrollClass)
      }
    })
  }
}